<template>
  <div class="container">
    <img src="/img/paladi.png" class="welcome__logo" />
    <form @submit.prevent="sendRequest()" class="personal-form">
      <div class="personal-form__item">
        <div class="personal-form__label">
          Укажите номер мобильного телефона и мы пришлем СМС с кодом подтверждения
        </div>
        <FormText :pattern="'+7 (###) ###-##-##'" v-model="phone"/>
      </div>
      <div class="personal-form__item">
        <button class="btn personal-form__submit">
          Отправить код
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";

export default {
  name: "Forgot-password",

  components: {
    FormText,
  },

  data() {
    return {
      phone: '',
      code: null,
    }
  },

  methods: {
    sendRequest() {
      this.$store
        .dispatch("FORGOT_PASSWORD_SMS_CODE", {
          phone: this.phone
        })
        .then(() => {
          this.$router.push("/forgot-password/confirm-code");
        })
    },
  }
}
</script>
